import React, {useCallback, useRef} from 'react'

import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
// import { routes } from '../routes';
// import Typography from '@mui/material/Typography'
// import version from '../version.js'

const Footer = () => {
  const click = useRef({});
  const handleClick = useCallback(() => {
    if (click.current.time && new Date() - click.current.time < 5000) {
      // console.log('tttt', new Date() - click.current.time)
      click.current.counter += 1;
    } else {
      // console.log('reset')
      click.current = {
        time: new Date(),
        counter: 1
      };
    }
    if (click.current.counter >= 7) {
      click.current = {
        time: new Date(),
        counter: 0
      };
      // console.log('go')
      window.location.hash = '#terminal';
    }
  }, []);

  return (
    <Box sx={{ marginTop: 'auto' }} className="footer">
      <Box sx={{ mx: 'auto', mt: 0 }} align='center' onClick={handleClick}>
        Copyright &copy; 2024 <Link href='https://yaosheng.io/' target='_blank' underline='hover' color='inherit'>YAOSHENG</Link>
      </Box>
    </Box>
  )
}

export default Footer

