import React, {useCallback, useEffect, useState} from 'react'
import { FaTemperatureHalf } from "react-icons/fa6";
import { parseFirmware, parseReceived } from '../common';
import { AiFillThunderbolt } from "react-icons/ai";
import { BsActivity } from "react-icons/bs";
import { GiRadarDish } from "react-icons/gi";
// import { RxTimer } from "react-icons/rx";
import { FaPlugCircleBolt } from "react-icons/fa6";
import { BsBroadcastPin } from "react-icons/bs";
// import { MdBattery5Bar } from "react-icons/md";
// import MenuBtn from './MenuBtn';
import Ident, { Ident2 } from "./Ident";
import Card, {DEFAULT_DELAY} from "./Card";
import HeatMap from "./HeatMap";
import BatteryCard, { BtnSet } from "./BatteryCard";
// import { MdOutlineSpeed } from "react-icons/md";
import { SiSpeedtest } from "react-icons/si";

const Dashboard = function(props) {
  // const [ident, setIdent] = useState(null);
  // const [stat, setStat] = useState(null);
  const [battery, setBattery] = useState({value: [null, null]});
  const [ident, setIdent] = useState({value : {}});
  const [version, setVersion] = useState({value : {}});
  const [stat, setStat] = useState({value: {}});
  // const [stat, setStat] = useState({value: {BOOST: "auto/on", VIN: "11.9V", VOUT: "53.8V", IOUT: "0.1A", POUT: "4W", T: "31C", PSE: "auto", TPSE: "35C", IPSE: "0.0A", PPSE: "0W", ERROR: "ERR_NONE"}});

  const lastCmd = React.useRef(null)
  const receivedBlob = React.useRef(null)

  const timer = React.useRef(null)
  const repeatTimer = React.useRef(null)
  const repeatAttempts = React.useRef(0)
  const tick = React.useRef(null)
  // const queue = React.useRef([])

  const sendCmd = useCallback((cmd, delay = 1) => {
    // console.log('sendCmd.changed')
    timer.current && clearTimeout(timer.current);
    repeatTimer.current && clearTimeout(repeatTimer.current);

    lastCmd.current = cmd;
    console.log('sendCmd', cmd)
    // send(cmd);
    timer.current = setTimeout(() => {
      // tick.current = new Date();
      console.log('tick', new Date().toLocaleTimeString());
      props.send(cmd);
    }, delay);

    repeatTimer.current = setTimeout(() => {
      if (!tick.current || new Date() - tick.current > DEFAULT_DELAY * 5) {
        if (repeatAttempts.current >= 2) {
          if (!props.port) {
            console.log('Disconnect');
            props.disconnect();
            return;
          }
          try {
            console.log('Reconnect port');
            props.port.disconnect()
            props.connectPort(props.port);
            repeatAttempts.current = 0;
            sendCmd(lastCmd.current);
          } catch (e) {
            console.log('Disconnect');
            props.disconnect();
          }
        } else {
          console.log('Repeat cmd', repeatAttempts.current);
          repeatAttempts.current += 1;
          sendCmd(lastCmd.current);
        }
      }
    }, DEFAULT_DELAY * 10);
  }, [props.send]);

  useEffect(() => {
    sendCmd('IDENT?', DEFAULT_DELAY);
    return () => {
      timer.current && clearTimeout(timer.current);
      repeatTimer.current && clearTimeout(repeatTimer.current);
    };
  }, []);

  useEffect(
    () => {
      tick.current = new Date();
      console.log('received changed', props.received);

      if (props.received.error) {
        if ((props.received.error + '').indexOf('transferOut') !== -1) {
          props.disconnect();
        }
        return;
      }
      repeatAttempts.current = 0;

      if (!props.received.value) return;
      receivedBlob.current = (receivedBlob.current || '') + props.received.value;
      console.log('received', {current: receivedBlob.current})
      if (!receivedBlob.current.endsWith('\r\n')) {
        return;
      }

      const fullReceived = receivedBlob.current;
      receivedBlob.current = '';

      // const receivedOK = fullReceived === "OK\r\n";

      const parsed = parseReceived(fullReceived);
      console.log({parsed});

      if (lastCmd.current && lastCmd.current.startsWith('BATTERY:')) {
        if (fullReceived === "OK\r\n") {
          sendCmd('BATTERY?', 500);
        } else if (parsed.cmd) {
          if (parsed.value && parsed.cmd === 'STAT') {
            setStat(parsed);
          }
          return;
        } else {
          sendCmd('STAT?', DEFAULT_DELAY);
        }
        return;
      }

      if (parsed && parsed.value) {
        if (parsed.cmd === 'STAT' && lastCmd.current === 'STAT?') {
          setStat(parsed);
          sendCmd('STAT?', DEFAULT_DELAY);
        }
        else if (parsed.cmd === 'BATTERY' && lastCmd.current === 'BATTERY?') {
          setBattery(parsed);
          sendCmd('STAT?', DEFAULT_DELAY/2);
        }
        else if (parsed.cmd === 'IDENT' && lastCmd.current === 'IDENT?') {
          setIdent(parsed);
          setVersion({value: parseFirmware(parsed.value.FW)})
          sendCmd('BATTERY?', DEFAULT_DELAY/2);
        }
      } else {
        lastCmd.current && sendCmd(lastCmd.current, DEFAULT_DELAY);
      }
    },
    [props.received],
  );

  console.log({stat})

  return (
    <div className="dash main-container">
      {/*<h3 className="head-3 cl-light mb-2">*/}
      {/*  Device*/}
      {/*</h3>*/}

      <Ident2 ident={ident} />

      <h3 className="head-3 cl-light mb-2 mt-4">
        Status
      </h3>

      <div className="card-grid-wrapper">

        <Card single title="BOOST"
              // Icon={RxTimer}
              // Icon={MdOutlineSpeed}
              Icon={SiSpeedtest}
              value={stat.value.BOOST}
              bg="#0aa8a720" color="#1976d2" />

        <BatteryCard
          title="Battery"
          sendCmd={sendCmd}
          min={battery.value[0]}
          max={battery.value[1]} />

        <Card title="Temperature"
              Icon={FaTemperatureHalf}
              bg="#ffa50045" color="orange" chartbg="#ffa50020"
              axis="T" data={stat}
              unit="°C"
        />

        <Card title="Input Voltage"
              Icon={AiFillThunderbolt}
              bg="#8884d823" color="#8884d8"
              axis="VIN" data={stat}
              unit="V"
        />

        <Card title="Output Voltage"
              Icon={AiFillThunderbolt}
              bg="#216ce025" color="#216ce0" fill="#216ce015"
              axis="VOUT" data={stat}
              unit="V"
        />

        <Card title="Output Current"
              Icon={BsActivity}
              bg="#7aeda634" color="#30cc9f"
              axis="IOUT" data={stat}
              unit="A"
        />

        <Card title="Output Power"
              Icon={BsBroadcastPin}
              bg="#d7ffff" color="#008080" fill="#B3FFFF"
              axis="POUT" data={stat}
              unit="W"
        />
      </div>

      <h3 className="head-3 cl-light mb-2 mt-4">
        PSE (Power Sourcing Equipment)
      </h3>

      <div className="card-grid-wrapper">

        {
          version.value.major > 1 ? (
            <React.Fragment>
              <Card single title="Dish"
                Icon={GiRadarDish}
                value={stat.value.DISH}
                bg="#0aa8a720" color="#1976d2"
              />
              <Card dual title="PSE"
                Icon={FaPlugCircleBolt}
                bg="#8884d823" color="#8884d8"
                value={stat.value.PSE}
              />
            </React.Fragment>
          ) : (
          <Card full title="PSE"
            Icon={FaPlugCircleBolt}
            bg="#8884d823" color="#8884d8"
            value={stat.value.PSE} />
          )
        }

        <Card title="Temperature"
              Icon={FaTemperatureHalf}
              bg="#ffa50045" color="orange" chartbg="#ffa50020"
              axis="TPSE" data={stat}
              unit="°C" />

        <Card title="Current"
              Icon={BsActivity}
              bg="#7aeda634" color="#30cc9f"
              axis="IPSE" data={stat}
              unit="A" />

        <Card title="Power"
              Icon={BsBroadcastPin}
              bg="#d7ffff" color="#008080" fill="#B3FFFF"
              axis="PPSE" data={stat}
              unit="W" />
      </div>
      <HeatMap
        title="Low / High Values"
        data={stat}
        axis={{
          T: {
            name: "Temperature",
            unit: "°C"
          },
          VIN: {
            name: "Input Voltage",
            unit: "V"
          },
          VOUT: {
            name: "Output Voltage",
            unit: "V"
          },
          IOUT: {
            name: "Output Current",
            unit: "A"
          },
          POUT: {
            name: "Output Power",
            unit: "W"
          },
          TPSE: {
            name: "PSE Temperature",
            unit: "°C"
          },
          IPSE: {
            name: "PSE Current",
            unit: "A"
          },
          PPSE: {
            name: "PSE Power",
            unit: "W"
          },
        }}/>

      {/*<div className="card-grid-wrapper">*/}
      {/*  <HeatMap*/}
      {/*    title="Low / High Values"*/}
      {/*    data={stat}*/}
      {/*    axis={{*/}
      {/*      T: {*/}
      {/*        name: "Temperature",*/}
      {/*        unit: "°C"*/}
      {/*      },*/}
      {/*      VIN: {*/}
      {/*        name: "Input Voltage",*/}
      {/*        unit: "V"*/}
      {/*      },*/}
      {/*      VOUT: {*/}
      {/*        name: "Output Voltage",*/}
      {/*        unit: "V"*/}
      {/*      },*/}
      {/*      IOUT: {*/}
      {/*        name: "Output Current",*/}
      {/*        unit: "A"*/}
      {/*      },*/}
      {/*      POUT: {*/}
      {/*        name: "Output Power",*/}
      {/*        unit: "W"*/}
      {/*      },*/}
      {/*    }}/>*/}

      {/*  <HeatMap*/}
      {/*    title="Low / High Values"*/}
      {/*    data={stat}*/}
      {/*    axis={{*/}
      {/*      T: {*/}
      {/*        name: "Temperature",*/}
      {/*        unit: "°C"*/}
      {/*      },*/}
      {/*      VIN: {*/}
      {/*        name: "Input Voltage",*/}
      {/*        unit: "V"*/}
      {/*      },*/}
      {/*      VOUT: {*/}
      {/*        name: "Output Voltage",*/}
      {/*        unit: "V"*/}
      {/*      },*/}
      {/*      IOUT: {*/}
      {/*        name: "Output Current",*/}
      {/*        unit: "A"*/}
      {/*      },*/}
      {/*      POUT: {*/}
      {/*        name: "Output Power",*/}
      {/*        unit: "W"*/}
      {/*      },*/}
      {/*    }}/>*/}
      {/*</div>*/}
    </div>
  );

  // [PN:YSNEAPL22010A, SN:0123456789ABCDEF, MFD:20240626, FW:GEN3_1.02, HW:1.0]
  // STAT: [BOOST:auto/on, Vin:11.9V, Vout:53.8V, Iout:0.1A, Pout:4W, T:31C, PSE:auto, Tpse:35C, Ipse:0.0A, Ppse:0W, Error:ERR_NONE]
  // STAT: [
  // BOOST:auto/on, PSE:auto,
  // Vin:11.9V, Vout:53.8V,
  // Iout:0.1A, Pout:4W, T:31C,
  // Tpse:35C, Ipse:0.0A, Ppse:0W,
  // Error:ERR_NONE]
}

export default Dashboard;
