import React, {useEffect, useRef, useState} from "react";
import {extractFloat} from "../common";

const TIME_MINUTE = 60;
const TIME_HOUR = 60 * TIME_MINUTE;
const ROTATE_VAL = 20;

const range_scale = [
  TIME_MINUTE/8,
  TIME_MINUTE/4,
  TIME_MINUTE,
  2 * TIME_MINUTE,
  5 * TIME_MINUTE,
  10 * TIME_MINUTE,
  15 * TIME_MINUTE,
  30 * TIME_MINUTE,
  TIME_HOUR
];

// function heatval(value, minmax) {
//   const {min, max} = minmax;
//   if (value <= min) return 1;
//   if (value >= max) return 8;
//   const v = Math.ceil(((value - min) / (max - min)) * 6);
//   return v > 0 ? v + 1 : 2;
// }

function heatval(value, minmax) {
  const {min, max} = minmax;
  if (value <= min) return 1;
  if (value >= max) return 8;
  const v = ((value - min) / (max - min)) * 100;
  return v <= 4 ? 2 : v <= 15 ? 3 : v <= 50 ? 4 : v <= 85 ? 5 : v <= 96 ? 6 : 7;
}

function isDiffDay(date1, date2) {
  return date1.getDate() !== date2.getDate() || date1.getMonth() !== date2.getMonth();
}

function formatDate(date, day = false) {
  const options = { hour: '2-digit', minute: '2-digit', second: '2-digit' };
  if (day) {
    options.day = 'numeric';
    options.month = 'short';
  }
  return date.toLocaleString('en-GB', options);
}

function joinData(data1, data2) {
  const data = {
    time: [data1.time[0], data2.time[1]]
  };
  Object.keys(data1).forEach(k => {
    if (k === 'time') return;
    const min = Math.min(data1[k].min.value, data2[k].min.value);
    const max = Math.max(data1[k].max.value, data2[k].max.value);

    data[k] = {
      min: {
        value: min,
        time: min === data1[k].min.value ? data1[k].min.time : data2[k].min.time
      },
      max: {
        value: max,
        time: max === data1[k].max.value ? data1[k].max.time : data2[k].max.time
      },
    }
  });
  return data;
}

function HeatMap (props) {
  const [data, setData] = useState([]);
  const scale = useRef(0);
  const showDate = useRef(false);
  const minmax = useRef({});

  useEffect(() => {
    setData(prevData => {
      const time = props.data.time;
      if (!time) return prevData;

      let idx = prevData.length ? prevData.length - 1 : -1;
      let latest = prevData.length ? prevData[idx] : {
        time: [time, time]
      };

      const range = range_scale[scale.current] * 1000;
      // console.log({range, diff: time - latest.time[0]})
      if (time - latest.time[0] > range) {
        idx = -1;
        latest = {time: [time, time]};
      }
      // reformat; @TODO

      Object.keys(props.axis).forEach(k => {
        const newValue = extractFloat(props.data.value[k]);

        if (!latest[k]) {
          minmax.current[k] = {
            min: minmax.current[k] ? Math.min(minmax.current[k].min, newValue) : newValue,
            max: minmax.current[k] ? Math.max(minmax.current[k].max, newValue) : newValue,
          };
          latest[k] = {
            min: {value: newValue, time},
            max: {value: newValue, time},
          };
        } else {
          const min = Math.min(latest[k].min.value, newValue);
          const max = Math.max(latest[k].max.value, newValue);

          if (min < latest[k].min.value) {
            latest[k].min.value = min;
            latest[k].min.time = time;
            minmax.current[k].min = Math.min(minmax.current[k].min, min);
          }
          if (max > latest[k].max.value) {
            latest[k].max.value = max;
            latest[k].max.time = time;
            minmax.current[k].max = Math.max(minmax.current[k].max, max);
          }
        }
      });

      latest.time[1] = time;

      if (!showDate.current) {
        const first = prevData.length ? prevData[0] : {
          time: [time, time]
        };

        showDate.current = isDiffDay(first.time[0], latest.time[1]);
      }

      // showDate.current = false;

      if (idx === -1) {
        prevData.push(latest);
      }

      if (prevData.length > ROTATE_VAL && range_scale[scale.current + 1]) {
        scale.current += 1;
        const newData = [];
        for (let j = 0; j < prevData.length; j+=2) {
          const d = prevData[j+1] ? joinData(prevData[j], prevData[j+1]) : prevData[j];
          newData.push(d);
        }
        return newData;
      }

      return prevData;
    });
  }, [props.data]);

  return !data.length ? '' : (
    <div className={`heatmap heatmap-size-${Object.keys(props.axis).length}`}>
      <div className="heatmap-head">
        <div className="heatmap-title">{props.title || ''}</div>
        {
          Object.keys(props.axis).map(k => {
            return (
              <div className="heatmap-hd">
                <div className="heatmap-hd-name">
                  {props.axis[k].name}
                </div>
                <div className="heatmap-hd-low">
                  low
                </div>
                <div className="heatmap-hd-high">
                  high
                </div>
              </div>
            );
          })
        }
      </div>
      <div className="heatmap-body">
        {
          data.map(d => (
            <div className="heatmap-row">
              <div className="heatmap-time">
                {formatDate(d.time[0], showDate.current || 0)} - {formatDate(d.time[1])}
              </div>
              {
                Object.keys(props.axis).map(k => {
                  return (
                    <div className="heatmap-col">
                      <div className={`heatmap-col-low heatmap-heatval-${heatval(d[k].min.value, minmax.current[k])}`}>
                        <div className="heatmap-col-time">
                          {formatDate(d[k].min.time)}
                        </div>
                        <div className="heatmap-col-val">
                          {d[k].min.value} <span className="heatmap-unit">{props.axis[k].unit}</span>
                        </div>
                      </div>
                      <div className={`heatmap-col-high heatmap-heatval-${heatval(d[k].max.value, minmax.current[k])}`}>
                        <div className="heatmap-col-time">
                          {formatDate(d[k].max.time)}
                        </div>
                        <div className="heatmap-col-val">
                          {d[k].max.value} <span className="heatmap-unit">{props.axis[k].unit}</span>
                        </div>
                      </div>
                    </div>
                  );
                })
              }
            </div>
          ))
        }
      </div>
    {/*</div>*/}
  </div>);
}

export default HeatMap;
