import clsx from 'clsx'
import {useEffect, useState} from "react";

function Loader(props) {
  const [style, setStyle] = useState({});
  useEffect(() => {
    const css = {};
    if (props.width) {
      css["--spinner-width"] = props.width;
    }
    if (props.size) {
      css["--spinner-size"] = props.size;
    }
    if (props.color) {
      css["--spinner-color"] = props.color;
    }
    setStyle(css);
  }, [props.width, props.size, props.color]);

  const cls = [props.center]

  return (
    <div className={clsx('loader', {
      'center': props.center,
      'inset': props.inset,
    })} style={style} />
  )
}

export default Loader;
