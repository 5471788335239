import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function ChromeIcon(props) {
  return (
    <SvgIcon {...props} viewBox='0 0 512.003 512.003'>
      <path fill='#f44336' d='M61.818 89.204c119.008-138.496 339.68-111.168 422.144 50.624-58.08.032-148.992-.032-198.24 0-35.712 0-58.784-.8-83.744 12.352-29.344 15.456-51.52 44.096-59.232 77.76L61.818 89.204z' />
      <path fill='#2196f3' d='M170.842 256.02c0 46.944 38.176 85.12 85.088 85.12s85.088-38.176 85.088-85.12-38.176-85.12-85.088-85.12c-46.944-.032-85.088 38.176-85.088 85.12z' />
      <path fill='#4caf50' d='M288.922 367.444c-47.744 14.176-103.648-1.536-134.24-54.4-23.36-40.32-85.12-147.872-113.152-196.8C-56.774 266.9 27.93 472.18 206.362 507.22l82.56-139.776z' />
      <path fill='#ffc107' d='M334.938 170.868c39.776 36.992 48.448 96.896 21.504 143.328-20.288 35.008-85.088 144.352-116.48 197.28 183.84 11.328 317.856-168.832 257.312-340.64l-162.336.032z' />
    </SvgIcon>
  )
}