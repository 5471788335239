function extractFloat(str) {
  const cleanedStr = (str || '').replace(/[^\d.]/g, '');
  try {
    return parseFloat(cleanedStr);
  } catch(e) {
    return 0;
  }
}
export {extractFloat};

const REG_VERSION = /^(.*)_([0-9]*)\.([0-9\.]*)$/gi;

function parseFirmware(val) {
  REG_VERSION.lastIndex = 0;
  const m = REG_VERSION.exec(val);
  if (m) {
    return {
      gen: m[1],
      major: parseInt(m[2]),
      minor: m[3],
      time: new Date()
    };
  }
  return null;
}

const REG_ALL = /^([A-Z]+):\s*\[(.*)\]\r\n$/gi;
const REG_PRM = /([A-Z]+):\s*([^:,]*),?/gi;
const REG_VAL = /([^:,\[\]\s]*),?\s*/g;

function parseReceived(received) {
  REG_ALL.lastIndex = 0;
  let m = REG_ALL.exec(received);
  if (m) {
    const cmd = m[1];
    const raw = m[2];
    let value = {};

    REG_PRM.lastIndex = 0;
    while (true) {
      m = REG_PRM.exec(raw);
      // console.log({m})
      if (!m) break;
      value[m[1].toUpperCase()] = m[2];
    }

    if (Object.keys(value).length === 0) {
      value = [];
      REG_VAL.lastIndex = 0;
      while (true) {
        m = REG_VAL.exec(raw);
        if (!m || !m[1].length) break;
        value.push(m[1]);
      }
    }

    return {
      cmd,
      value,
      raw,
      time: new Date()
    }
  }
  return null;
}

function isSetCommand(cmd) {


}

function packData(time, data) {

}

// function packChartData(data)

export {parseReceived, parseFirmware};
