import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CheckIcon from '@mui/icons-material/Check';
import * as React from "react";
import Card from "./Card";
import {MdBattery5Bar} from "react-icons/md";
import {extractFloat} from "../common";
import {useEffect, useState} from "react";
import NumberInput from "./NumberInput";


function BtnSet(props) {
  const handleClick = (event) => {
    props.onClick && props.onClick(event);
  };

  return (
    <IconButton
      aria-label="more"
      aria-haspopup="true"
      onClick={handleClick}
    >
      <MoreVertIcon />
    </IconButton>
  )
}

function BatteryCard(props) {
  const [edit, setEdit] = useState(false);
  const [changed, setChanged] = useState(false);
  const [min, setMin_] = useState(extractFloat(props.min));
  const [max, setMax_] = useState(extractFloat(props.max));

  const setMin = val => {
    setChanged(true);
    setMin_(val);
  };
  const setMax = val => {
    setChanged(true);
    setMax_(val);
  };

  const handleEdit = (event) => {
    if (changed) {
      console.log(`BATTERY: [${min}V, ${max}V]`)
      props.sendCmd(`BATTERY: [${min}V, ${max}V]`);
      setChanged(false);
    }
    setEdit(!edit);
  };

  useEffect(() => {
    setMin_(extractFloat(props.min));
    setMax_(extractFloat(props.max));
  }, [props.min, props.max])

  return (
    <Card dual title={props.title}
          className={edit ? 'card-dual-edit battery-card' : 'battery-card'}
          Icon={MdBattery5Bar}
          value={0 ? false :
            props.min ? <span>
              {extractFloat(props.min)} <span className="info-unit">V</span>
              &nbsp;-&nbsp;
              {extractFloat(props.max)} <span className="info-unit">V</span>
            </span>: null}
          bg="#0441ff24" color="#1976d2">
      {
        props.min && edit ? (
          <div className="grid-edit">
            <div className="form-control">
              <label>Min:</label>
              <NumberInput
                min={10}
                max={max}
                step={0.01}
                value={extractFloat(props.min)}
                onBlur={val => setMin(val)}
              />
            </div>
            <div className="form-control">
              <label>Max:</label>
              <NumberInput
                min={min}
                max={30}
                step={0.01}
                value={extractFloat(props.max)}
                onBlur={val => setMax(val)}
              />
            </div>
          </div>) : ''
      }
      {props.min && (<div className="card-dot">
        <IconButton
          aria-label="more"
          aria-haspopup="true"
          onClick={handleEdit}
        >
          {edit ? <CheckIcon /> : <MoreVertIcon />}
        </IconButton>
      </div>)}
    </Card>
  )
}

export default BatteryCard;
