import {BsUsbSymbol} from "react-icons/bs";
import Loader from "./Loader";
import React from "react";
import Card, {DEFAULT_DELAY, ImgHolder} from "./Card";
import {FaTemperatureHalf} from "react-icons/fa6";
import Chart from "./Chart";
import { ReactComponent as PoEIcon } from '../icons/power-over-ethernet.svg';


function Ident({ident}) {
  return (
    <div className="card device-info">
      <div className="img-holder">
        <BsUsbSymbol />
        {/*<PoEIcon />*/}
      </div>
      {
        ident ?
          <div className="info-holder">
            <div className="dev-pn">
              Model: <span>{ident.value.PN}</span>
            </div>
            <div className="info-data">
              <div className="info-left">
                <div className="dev-sn">
                  Serial Number: <span>{ident.value.SN}</span>
                </div>
                <div className="dev-mfd">
                  Manufacture Date: <span>{ident.value.MFD}</span>
                </div>
              </div>
              <div className="info-right">
                <div className="dev-wf">
                  Firmware Version: <span>{ident.value.FW}</span>
                </div>
                <div className="dev-hw">
                  Hardware Version: <span>{ident.value.HW}</span>
                </div>
              </div>
            </div>
          </div>
          : <div className="relative info-holder">
            <Loader inset width="3px" size="50px"/>
          </div>
      }
    </div>
  )
}


function Ident2({ident}) {
  return (
    <div className="card-grid-wrapper">
      <div className="card card-grid card-ident relative">
        {/*<ImgHolder Icon={BsUsbSymbol}  />*/}
        <ImgHolder Icon={PoEIcon}  />

        <div className="grid-right" >
          {/*CONTENT*/}

        {/*<div className="img-holder">*/}
        {/*  <BsUsbSymbol />*/}
        {/*</div>*/}
        {
          ident && ident.value && ident.value.PN ?
            <div className="info-holder">
              <div className="dev-name">
                <b>GigE PoE Injector</b>
              </div>
              <div className="dev-pn">
                Model: <span>{ident.value.PN}</span>
              </div>
              <div className="info-data">
                <div className="info-left">
                  <div className="dev-sn">
                    Serial Number: <span>{ident.value.SN}</span>
                  </div>
                  <div className="dev-mfd">
                    Manufacture Date: <span>{ident.value.MFD}</span>
                  </div>
                </div>
                <div className="info-right">
                  <div className="dev-wf">
                    Firmware Version: <span>{ident.value.FW}</span>
                  </div>
                  <div className="dev-hw">
                    Hardware Version: <span>{ident.value.HW}</span>
                  </div>
                </div>
              </div>
            </div>
            : <div className="info-holder">
              <Loader inset width="3px" size="50px" color="#1976d2" />
            </div>
        }
        </div>
      </div>
    </div>
  )
}

export {Ident2};

export default Ident;
