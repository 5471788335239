import React from "react";
import clsx from "clsx";
import {extractFloat} from "../common";
import Chart from "./Chart";
import Loader from "./Loader";

const DATA_LEN = 1300;
const DEFAULT_DELAY = 1400;

function ImgHolder({Icon, bg, color}) {
  return (
    <div className="info-img">
      <div className="img-holder" style={{background: bg, color: color}}>
        <Icon />
      </div>
    </div>
  )
}

function Card({className, Icon, single, full, dual, children, chartbg, bg, color, fill, stroke, title, value, unit, data, axis}) {
  if (single || dual || full) {
    return (
      <div className={clsx('card card-grid', {'card-single': single, 'card-dual': dual, 'card-single-fill': full}, className)}>
        <ImgHolder Icon={Icon} bg={bg} color={color} />

        <div className="grid-left">
          <div className="info-title">
            {title}
          </div>
          <div className="info-value">
            {value === false ? '' : (value || <Loader width="1px" size="16px" color={color} inset />)}
          </div>
        </div>
        { children || '' }
      </div>
    );
  }

  return (
    <div className="card card-grid">
      <ImgHolder Icon={Icon} bg={bg} color={color} />

      <div className="grid-left">
        <div className="info-title">
          {title}
        </div>
        <div className="info-value">
          {value || data.value[axis] ? (
              <span>{extractFloat(value || data.value[axis])} <span className="info-unit">{unit}</span></span>) :
            <Loader width="1px" size="16px" color={color} inset />
          }
        </div>
      </div>

      <div className="grid-right relative chart-holder" style={{ background: chartbg || bg}}>
        <Chart delay={DEFAULT_DELAY}
               len={DATA_LEN}
               axis={{
                 [axis]: {stroke: stroke || color, fill: fill || bg}
               }}
               minmax={axis}
               unit={unit}
               data={data}/>
      </div>
    </div>
  );
}

export default Card;
export {ImgHolder, DEFAULT_DELAY};
