import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'

import Button from '@mui/material/Button'

import ChromeIcon from '../icons/Chrome'
import EdgeIcon from '../icons/Edge'
import OperaIcon from '../icons/Opera'

function Unsupported() {
    const [isIOS, setIsIOS] = useState(false);
    // const [isIOS, setIsIOS] = useState(true);
    useEffect(() => {
        if (/iPad|iPhone|iPod/.test(window.navigator.userAgent)) {
            setIsIOS(true);
        }
    }, []);

    return (
        <div className="unsupported">
            <h3>Unsupported browser</h3>

            {isIOS && (<React.Fragment>
                  <p>Unfortunately, WebUSB is not supported on iOS devices. Please use a supported browser on a different platform.</p>
            </React.Fragment>)}
            {!isIOS && (<React.Fragment>
                <p>You're using a web browser that does not support WebUSB, which is required.</p>
              <p>Please use a supported browser such as Google Chrome, Microsoft Edge, or Opera.</p>
            </React.Fragment>)}
            {/*<p>You're using a web browser that does not support WebUSB.</p>*/}
            {/*<p>If you believe this is an error, please contact our support team.</p>*/}

            <div className="browsers">
              <div className="browser">
                  <a href="https://www.google.com/chrome/" target="_blank" className="browser-img">
                      <ChromeIcon fontSize='inherit' />
                  </a>
                  <a href="https://www.google.com/chrome/" target="_blank" className="browser-link">
                      <b>Google Chrome</b>
                      <span>version 56+</span>
                  </a>
              </div>

              <div className="browser">
                  <a href="https://www.microsoft.com/en-us/edge" target="_blank" className="browser-img">
                      <EdgeIcon fontSize='inherit' />
                  </a>
                  <a href="https://www.microsoft.com/en-us/edge" target="_blank" className="browser-link">
                      <b>Microsoft Edge</b>
                      <span>version 79+</span>
                  </a>
              </div>

              <div className="browser">
                  <a href="https://www.opera.com/" target="_blank" className="browser-img">
                      <OperaIcon fontSize='inherit' />
                  </a>
                  <a href="https://www.opera.com/" target="_blank" className="browser-link">
                      <b>Opera</b>
                      <span>version 42+</span>
                  </a>
              </div>
            </div>

            <p className="more">
              Learn more about&nbsp;
              <a href='https://developer.mozilla.org/en-US/docs/Web/API/USB#browser_compatibility' target='blank'>
                  browser compatibility
              </a>
            </p>
        </div>
    );
}

function Home(props) {
    return (
        <div className="home">
            {!props.supported() ? <Unsupported/> :
                <div className="connect-btn-center-wrap">
                    <div className="connect-btn-center">
                        <div className="connect-btn-wrap">
                            <div className="connect-btn-container">
                                <Button id="primary-btn" variant='contained' color='primary' size='large' onClick={props.connect} sx={{ m: 1 }}>
                                    Connect
                                </Button>
                            </div>
                        </div>

                        <div className="connect-info">
                            <div>
                                Plug in your device<br/>
                                and connect
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

Home.propTypes = {
    connect: PropTypes.func,
    supported: PropTypes.func,
}

export default Home;
