import { BiHomeAlt2 } from "react-icons/bi";
import { FiSearch } from "react-icons/fi";
import { PiChatCircleBold } from "react-icons/pi";
import { IoPricetagsOutline } from "react-icons/io5";
import { AiFillApi } from "react-icons/ai";
import { AiFillBulb } from "react-icons/ai";
import { AiOutlineSetting } from "react-icons/ai";
import { AiOutlineBulb } from "react-icons/ai";
import PowerOffIcon from '@mui/icons-material/PowerOff'
import { AiOutlineApi } from "react-icons/ai";
import { MdOutlinePowerOff } from "react-icons/md";
import { MdOutlinePower } from "react-icons/md";

export const routes = [
  {
    title: "Dashboard",
    href: "#",
    Icon: BiHomeAlt2,
    active: connected => connected
  },
  // {
  //   title: "Settings",
  //   href: "#settings",
  //   Icon: AiOutlineSetting,
  //   active: connected => connected
  // },
  {
    title: "About",
    href: "#about",
    Icon: AiOutlineBulb,
  },
  {
    title: "Disconnect",
    action: "disconnect",
    href: "#",
    // Icon: PowerOffIcon,
    Icon: MdOutlinePowerOff,
    active: connected => connected
  },
  {
    title: "Connect",
    action: "connect",
    href: "#",
    // Icon: AiFillApi,
    // Icon: AiOutlineApi,
    Icon: MdOutlinePower,
    active: connected => !connected
  },
];
