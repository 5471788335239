import React, {useCallback, useEffect, useRef} from 'react'

import Box from '@mui/material/Box'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

import Header from './components/Header'
import Footer from './components/Footer'
import Home from './components/Home'
import Terminal from './components/Terminal'
import Settings from './components/Settings'
import ErrorMessage from './components/ErrorMessage'

// import Serial from './modules/Serial'
import { setCookie, getCookie } from './modules/cookie.js'
import serial from "./serial";
import Dashboard from "./components/Dashboard";

const loadSettings = () => {
  let settings = {
    baudRate: 115200,
    lineEnding: '\\r\\n',
    echoFlag: true,
    timeFlag: false,
    ctrlFlag: true,
  }

  const cookieValue = getCookie('settings');

  if (cookieValue) {
    try {
      const cookieJSON = JSON.parse(cookieValue)

      if ('baudRate' in cookieJSON) settings.baudRate = cookieJSON.baudRate
      if ('lineEnding' in cookieJSON) settings.lineEnding = cookieJSON.lineEnding
      if ('echoFlag' in cookieJSON) settings.echoFlag = cookieJSON.echoFlag
      if ('timeFlag' in cookieJSON) settings.timeFlag = cookieJSON.timeFlag
      if ('ctrlFlag' in cookieJSON) settings.ctrlFlag = cookieJSON.ctrlFlag
    } catch (e) {
      console.error(e)
    }
  }

  //saveSettings(settings)
  return settings
}

const routes = {
  home: {
    href: '#',
    route: 'home',
  },
  settings: {
    href: '#settings',
    route: 'settings',
  },
  about: {
    href: '#about',
    route: 'about',
    action: 'about',
  },
  connect: {
    href: '#',
    route: 'home',
    action: 'connect',
  },
  disconnect: {
    href: '#',
    route: 'home',
    action: 'disconnect',
  },
  terminal: {
    href: '#terminal',
    route: 'terminal',
  },
};

function App() {
  // Connection routes
  const [route, setRoute] = React.useState('home')

  // Serial Module
  const [port, setPort] = React.useState(null)

  // Connection Flag
  const [connected, setConnected] = React.useState(false);
  const connecting = useRef(null);

  // Receive Buffer
  const [received, setReceived] = React.useState({ time: new Date(), value: '' })

  // Connect/Disconnect Toast Open
  const [toast, setToast] = React.useState({ open: false, severity: 'info', value: '' })

  // Settings Window Open
  const [settingsOpen, setSettingsOpen] = React.useState(false)

  // Error Window
  const [errorOpen, setErrorOpen] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')

  // Settings
  const [settings, setSettings] = React.useState(loadSettings())

  const saveSettings = (newSettings) => {
    // serial.setBaudRate(newSettings.baudRate)
    setSettings(newSettings)
    setCookie('settings', JSON.stringify(newSettings), 365)
  }

  const closeToast = () => {
    setToast({ ...toast, open: false })
  }

  const connectPort = useCallback((port) => {
    if (connecting.current) return;
    connecting.current = true;

    console.log('Connecting to ' + port.device_.productName + '...');
    console.log(port.device_);

    window.localStorage.setItem('connected', JSON.stringify({
      productId: port.device_.productId,
      vendorId: port.device_.vendorId
    }));

    port.connect().then(() => {
      console.log(port);
      // setToast({ open: true, severity: 'success', value: 'Connected to ' + port.device_.productName + ' 🚀' })
      console.log('Connected');

      // !connected && setReceived({
      //   time: new Date(),
      //   value: 'Connected to ' + port.device_.productName + "\r\n"
      // });

      port.onReceive = data => {
        let textDecoder = new TextDecoder();
        const value = textDecoder.decode(data);
        console.log('onReceive', {value})
        setReceived({
          time: new Date(),
          value: `${value}`,
        });
        setErrorMessage('');
      }

      port.onReceiveError = error => {
        error += '';
        console.log('Receive error: ' + error);
        // if (error !== errorMessage && error.indexOf('cancelled') === -1) {
        //   setErrorMessage(error);
        //   setErrorOpen(true);
        // }
        setReceived({
          time: new Date(),
          value: '',
          error
        });
      };

      setPort(port);
    }, error => {
      connecting.current = false;
      console.error(error);
      setToast({ open: true, severity: 'error', value: 'Connection error: ' + error});
      setPort(null);
      setConnected(false);
      window.localStorage.removeItem('connected');
    });

    setTimeout(function () {
      connecting.current = false;
      setConnected(true);
    }, 500);

  }, [connected]);

  const disconnect = useCallback((showToast=false) => {
    if (port) {
      const productName = port.device_.productName;
      const p = port;
      window.localStorage.removeItem('connected');
      setConnected(false);
      setPort(null);
      console.log({p})
      try {
        p.disconnect();
      } catch(e) {}
      console.log('Disconnected ' + productName, showToast);
      if (showToast) {
        setToast({ open: true, severity: 'success', value: 'Disconnected ' + productName })
      }
    }
  }, [port]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      try {
        port && port.disconnect();
      } catch(e) {}
      // event.returnValue = '';
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [port]);

  const connect = useCallback(() => {
    if (port) {
      disconnect();
    } else {
      serial.requestPort().then(selectedPort => {
        connectPort(selectedPort);
        setErrorMessage('');
      }).catch(error => {
        error += '';
        console.log('Connection error: ' + error);
        // if (error !== errorMessage) {
        //   setErrorMessage(error);
        //   setErrorOpen(true);
        // }
      });
    }
  }, [port, connectPort, disconnect, errorMessage]);

  useEffect(() => {
    let connectedDevice = window.localStorage.getItem('connected');
    if (connectedDevice) {
      try {
        connectedDevice = JSON.parse(connectedDevice);
        console.log({connectedDevice});
        if (connectedDevice.productId && connectedDevice.vendorId) {
          serial.getPorts().then(ports => {
            ports.forEach(port => {
              if (port.device_.productId === connectedDevice.productId && port.device_.vendorId === connectedDevice.vendorId) {
                connectPort(port);
                return false;
              }
            })
          })
        }
      } catch (e) {
        window.localStorage.removeItem('connected');
      }
    }

    const onHashChange = () => {
      let hash = window.location.hash;
      if (hash.startsWith('#')) {
        hash = hash.substring(1);
      }
      if (!hash.length) {
        hash = 'home'
      }
      console.log('Hash changed:', hash);

      if (routes[hash]) {
        const hashRoute = routes[hash];
        setRoute(hashRoute.route);
        window.location.href = hashRoute.href;
      }
    };
    window.addEventListener('hashchange', onHashChange);
    onHashChange();

    return () => {
      window.removeEventListener('hashchange', onHashChange);
    };
  // }, [connect, disconnect]);
  }, []);

  const handleSend = useCallback((str) => {
    const lineEnding = '\r\n';

    if (port && connected) {
      const textEncoder = new TextEncoder();
      port.send(textEncoder.encode(`${str}${lineEnding}`)).catch(error => {
        error += '';
        if (error !== errorMessage) {
          console.log('Send error: ' + error);
          setReceived({
            time: new Date(),
            value: ('Send error: ' + error) + "\r\n",
            error
          });

          // setErrorMessage(error);
          // setErrorOpen(true);
        }
      });
      console.log(JSON.stringify(`${str}${lineEnding}`))
    }
  }, [port, connected, errorMessage]);

  const handleRawSend = useCallback((byte) => {
    if (port) {
      const textEncoder = new TextEncoder();
      port.send(textEncoder.encode(byte)).catch(error => {
        console.log('Send error: ' + error);
        setReceived({
          time: new Date(),
          value: ('Send error: ' + error) + "\r\n"
        });
      });
    }
  }, [port]);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    }}>
      {/* Header */}
      <Header
        connected={connected}
        connect={connect}
        disconnect={disconnect}
      />

      {/* Homepage */}
      {!connected && (
        <Home
          key="home"
          connect={connect}
          supported={() => 'usb' in navigator}
          // openSettings={() => setSettingsOpen(true)}
        />
      )}

      {/* Dashboard */}
      {connected && route === 'home' && (
        <Dashboard
          key="dash"
          disconnect={disconnect}
          connectPort={connectPort}
          port={port}
          setToast={setToast}
          received={received}
          send={handleSend}
        />
      )}

      {/* Terminal */}
      {connected && route === 'terminal' && (
        <Terminal
          key="terminal"
          received={received}
          send={handleSend}
          sendRaw={handleRawSend}
          openSettings={() => setSettingsOpen(true)}
          echo={settings.echoFlag}
          time={settings.timeFlag}
          ctrl={settings.ctrlFlag}
          clearToast={() => setToast({ open: true, severity: 'info', value: 'History cleared 🧹' })}
        />
      )}

      {/* Settings Window */}
      <Settings
        open={settingsOpen}
        close={() => setSettingsOpen(false)}
        settings={settings}
        save={saveSettings}
        openPort={connected}
        saveToast={() => setToast({ open: true, severity: 'success', value: 'Settings saved ✨' })}
      />

      {/* (Dis)connected Toast */}
      <Snackbar open={toast.open} autoHideDuration={4000} onClose={closeToast}>
        <Alert onClose={closeToast} severity={toast.severity}>
          {toast.value}
        </Alert>
      </Snackbar>

      {/* Error Message Window */}
      <ErrorMessage
        open={errorOpen}
        close={() => setErrorOpen(false)}
        message={errorMessage}
      />

      {/* Footer */}
      <Footer />
    </Box>
  );
}

export default App