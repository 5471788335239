import React, { useState, useEffect, useRef } from 'react';
import { AreaChart, LineChart, Line, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {extractFloat} from '../common';


function fillData(len, delay, axis) {
  let time = (new Date()).getTime() - delay * len;
  const data = [];

  const axisData = {};
  axis.forEach(k => {
    axisData[k] = 0;
  });

  for (let i = 0; i < len; i ++, time += delay) {
    data.push({
      time: new Date(time),
      // ...axisData
    });
  }
  return data;
}

const CustomTooltip = ({ unit, active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip" style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px' }}>
        {payload.map((entry, index) => (
          <div key={`item-${index}`} style={{ color: entry.color }}>
            {`${entry.value} ${unit}`}
          </div>
        ))}
      </div>
    );
  }

  return null;
};

function timeAgo(date1, date2=new Date(), append='') {
  // console.log({date1, date2})
  const seconds = Math.floor((date2 - date1) / 1000);
  if (seconds < 60) {
    return `${seconds} sec`;
  }
  const minutes = Math.floor(seconds / 60);
  return `${minutes} min`;
}

const Chart = (props) => {
  const [data, setData] = useState(fillData(props.len, props.delay, Object.keys(props.axis)));
  const minValue = useRef({value: NaN});
  const maxValue = useRef({value: NaN});
  const startTime = useRef(null);

  useEffect(() => {
    const newData = {
      time: props.data.time
    };

    if (!startTime.current) {
      startTime.current = newData.time;
    }

    let axis = Object.keys(props.axis)[0];

    Object.keys(props.axis).forEach(k => {
      newData[k] = extractFloat(props.data.value[k]);
    });

    setData(prevData => {
      const updatedData = [...prevData, newData];
      if (updatedData.length > props.len) {
        updatedData.shift();
        if (updatedData[0][axis]) {
          startTime.current = updatedData[0].time;
        }
      }
      if (props.minmax) {
        const latest = updatedData[updatedData.length - 1];
        if (isNaN(minValue.current.value)) {
          minValue.current.value = latest[props.minmax];
          minValue.current.time = latest.time || new Date();
          maxValue.current.value = latest[props.minmax];
          maxValue.current.time = latest.time || new Date();
        } else {
          const min = Math.min(minValue.current.value, latest[props.minmax]);
          const max = Math.max(maxValue.current.value, latest[props.minmax]);

          if (min < minValue.current.value) {
            minValue.current.value = min;
            minValue.current.time = latest.time;
          }
          if (max > maxValue.current.value) {
            maxValue.current.value = max;
            maxValue.current.time = latest.time;
          }
        }
      }
      return updatedData;
    });
  }, [props.data, props.minmax, props.axis, props.len]);


  // const formatYAxis = (tickItem) => {
  //   return `${tickItem}${props.unit}`;
  // };

  const formatTooltip = (value, name) => {
    return [`${value}${props.unit}`, name];
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      {/*min: {minValue.current.value}<br/>*/}
      {/*max: {maxValue.current.value}<br/>*/}
      {
        props.minmax && !isNaN(maxValue.current.value) ? (
          <div className="chart-labels">
            {/*// <div className="chart-minmax" style={{color: props.axis[props.minmax].stroke, background: props.axis[props.minmax].fill}}>*/}
            <div className="chart-minmax" style={{color: props.axis[props.minmax].stroke}}>
              <span className="chart-max">low <span className="chart-min-value">{minValue.current.value} {props.unit}</span>
                {/*&nbsp;({timeAgo(minValue.current.time)})*/}
              </span>
              {/*title={timeAgo(maxValue.current.time)}*/}
              <span className="chart-min">high <span className="chart-min-value">{maxValue.current.value} {props.unit}</span>
                {/*&nbsp;({timeAgo(maxValue.current.time)})*/}
              </span>
            </div>
            <span className="chart-time" style={{color: props.axis[props.minmax].stroke}}>
              {timeAgo(startTime.current)}
            </span>
          </div>
        ) : <span />
      }
      {/*<LineChart data={data} >*/}
      <AreaChart data={data} >
        {/*<CartesianGrid strokeDasharray="3 3" />*/}
        {/*<XAxis dataKey="time" />*/}
        {/*<YAxis orientation="right" tickLine={false} axisLine={false}  tickFormatter={formatYAxis} />*/}
        <Tooltip formatter={formatTooltip} content={<CustomTooltip unit={props.unit} />} />
        {/*<Legend />*/}
        {
          Object.keys(props.axis).map(key =>
            // <Line type="monotone"
            <Area type="monotone"
                  isAnimationActive={false}
                  key={key} dataKey={key}
                  // dot={{ r: 26 }}
                  // dot={{ stroke: props.axis[key].stroke, strokeWidth: 0, r: 1.5, fill: props.axis[key].stroke }}
                  {...props.axis[key]}
            />
          )
        }
        {/*<Area type="monotone" dataKey="Vin" stroke="#8884d8" fill="#8884d8" />*/}
        {/*/!*<Area type="monotone" dataKey="Vout" stroke="#82ca9d" fill="#82ca9d" />*!/*/}
        {/*<Area type="monotone" dataKey="Vout" stroke="#216ce0" fill="#216ce0" />*/}
        {/*<Area type="monotone" dataKey="Pout" stroke="#ffc658" fill="#ffc658" />*/}
        {/*<Area type="monotone" dataKey="Ppse" stroke="#ff7300" fill="#ff7300" />*/}
      </AreaChart>
      {/*</LineChart>*/}
    </ResponsiveContainer>
  );
};

export default Chart;
