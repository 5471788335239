import { useClickAway } from "react-use";
import { useRef } from "react";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Squash as Hamburger } from "hamburger-react";
import { routes } from "../routes";

function HeaderNav(props) {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef(null);

  useClickAway(ref, () => setOpen(false));

  return (
    <div className="app-navbar-menu">
      <div ref={ref}>
        <Hamburger toggled={isOpen} size={20} toggle={setOpen} />
        <div className="app-navbar-menu-wrap">
          {/*<div>*/}
          <AnimatePresence>
            {isOpen && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="app-nav-dropdown left-0 shadow-4xl right-0 p-4"
              >
                <ul className="grid gap-2">
                  {routes.map((route, _) => {
                    const { Icon } = route;
                    if (route.active) {
                      if (!route.active(props.connected)) {
                        return '';
                      }
                    }

                    return (
                      <li
                        className="header-menu-li"
                        key={route.title}
                      >
                        <a
                          onClick={() => {
                            if (route.action) {
                              if (route.action === 'disconnect') {
                                props.disconnect()
                              }
                              if (route.action === 'connect') {
                                props.connect()
                              }
                            }
                            setOpen((prev) => !prev)
                          }}
                          href={ route.href ? route.href : '#'}
                        >
                          <Icon className="text-xl icon" />
                          <span className="flex gap-1 text-lg">{route.title}</span>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </motion.div>
            )}
          </AnimatePresence>
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
}

export default HeaderNav;
