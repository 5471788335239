import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import InputNumber from 'rc-input-number';
import {useRef, useState} from "react";
import {extractFloat} from "../common";

function NumberInput(props) {
  const [value, setValue] = useState(props.value);

  return (
    <InputNumber
      onFocus={() => console.log('ff')}
      upHandler={<KeyboardArrowUpIcon className="rc-input-number-handler-up-inner" />}
      downHandler={<KeyboardArrowDown className="rc-input-number-handler-down-inner" />}
      style={{ width: 100 }}
      {...props}
      value={value}
      onChange={val => setValue(val)}
      onBlur={() => props.onBlur && props.onBlur(value)}
    />
  )
}

export default NumberInput;
